import React from "react";
import classNames from "classnames";
import { Container, Text, AnimateIn } from "@atoms";

const Schedule = ({ days, order }) => {
  return (
    <section className={classNames("pb-20 md:pb-28")}>
      <div className="flex flex-col">
        {days?.map((day, i) => {
          const { uid, name, events } = day;
          return (
            <AnimateIn
              key={uid}
              preset="fadeUpFast"
              threshold="some"
              className={classNames(
                "pt-12 pb-12 first:pt-0 last:pb-0 sm:pt-28 sm:pb-28",
                {
                  "border-t-2 border-black": i !== 0,
                }
              )}
            >
              <Container>
                <div className="flex flex-wrap gap-12 sm:gap-20">
                  <div className="w-full sm:w-1/3 md:w-2/5">
                    <Text variant="h3">{name}</Text>
                  </div>
                  {!!events.length && (
                    <div className="flex flex-1 flex-col sm:pt-2.5 md:pt-3.5">
                      {events.map((event, ii) => {
                        const { uid: subuid, heading, descriptor } = event;
                        return (
                          <div
                            key={subuid}
                            className={classNames(
                              "pt-12 pb-12 first:pt-0 last:pb-0",
                              {
                                "border-t-2 border-black": ii !== 0,
                              }
                            )}
                          >
                            <Text variant="h6" className="whitespace-pre-line">
                              {heading}
                            </Text>
                            {descriptor && (
                              <Text richText className="mt-6 block">
                                {descriptor}
                              </Text>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </Container>
            </AnimateIn>
          );
        })}
      </div>
    </section>
  );
};

Schedule.defaultProps = {};

export default Schedule;
